<template>
  <div>
    <v-layout wrap dense>
      <v-flex xs12 md12 lg12 xl12 sm12>
        <v-app-bar style="font-family:opensansbold" app color="#F7F7F7" class="hidden-sm-and-down" dark fixed height="80px">
           <v-btn  href="/" text>
           <!-- <v-img src="../assets/logotext.png" style="width:60px;height:60px"> -->
           <v-img src="../assets/logotext.png" style="width:60px;height:60px">

           </v-img>
            <!-- <span style="color:black" class="mr-2" >JUMBO RADAR</span> -->
           
          </v-btn>
          
          <v-spacer></v-spacer>

          <v-btn  href="/" text>
            <span class="opensansbold mr-2" style="color:black;"  >Home</span>
          </v-btn>
          <!-- <v-btn v-scroll-to="{ element: '#aboutus', duration: 2000 }" text>
            <span class="mr-2">About Us</span>
          </v-btn> -->
          <v-btn class="opensansbold" style="color:black"  text href="/about" >About Us</v-btn>
          <!-- <v-btn v-scroll-to="{ element: '#videos', duration: 2000 }" text>
            <span class="mr-2">Videos</span>
          </v-btn>
          <v-btn  v-scroll-to="{ element: '#partners', duration: 2000 }" text>
            <span class="mr-2">Partners</span>
          </v-btn> -->
          <a href="https://admin.jumboradar.org/" style="text-decoration:none" target="blank" class="href">
             <v-btn text>
            <span style="color:black"  class="mr-2 opensansbold">Login</span>
          </v-btn>
          </a>
        </v-app-bar>
        <v-app-bar
          style="background-color: #F7F7F7"
         
          class="hidden-lg-and-up"
          fixed
        >
          <v-btn  href="/" text>
           <v-img src="../assets/logotext.png" style="width:40px;height:40px">
            <!-- <span style="color:black" class="mr-2" >JUMBO RADAR</span> -->

           </v-img>
          </v-btn>
          <v-app-bar-nav-icon v-on:click="draw = !draw"></v-app-bar-nav-icon>
           </v-app-bar>          
          <v-spacer></v-spacer>         
          <v-navigation-drawer
            v-model="draw"
            style="background-color: #F7F7F7"
            dark
            clipped
            temporary
            right
            fixed
            class="hidden-lg-and-up"
          >
          
           <v-layout wrap>
              <v-flex>               
            <v-btn href="/" text>
              <span style="color:black"  class="mr-2">Home</span>
            </v-btn>
              </v-flex>
           </v-layout>
            <v-layout wrap>
              <v-flex>
            <v-btn href="/about" text>
              <span style="color:black"  class="mr-2" >About Us</span>
            </v-btn>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex>
                 <a href="https://admin.jumboradar.org/" style="text-decoration:none" target="blank" class="href">
             <v-btn text>
            <span style="color:black"  class="mr-2">Login</span>
          </v-btn>
          </a>
              </v-flex>
            </v-layout>
             <!-- <v-layout wrap>
              <v-flex>
            <v-btn text >
              <span class="mr-2">What We Do</span>
            </v-btn>
              </v-flex>
             </v-layout>
              <v-layout wrap>
              <v-flex>
            <v-btn text>
              <span class="mr-2">Partners</span>
            </v-btn>
              </v-flex>
              </v-layout> -->
          </v-navigation-drawer>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      draw: false,
    };
  },
};
</script>
<style scoped>
/* .opensansbold
{
font-family:opensansbold

} */
</style>