<template>
  <v-app>
    <appHeader />
    <router-view></router-view>
    <foot />
  </v-app>
</template>
<script>
import appHeader from "./components/appHeader.vue";
import foot from "./components/foot.vue";
export default {
  name: "App",
  components: {
   appHeader,
    foot,
  },

  data: () => ({}),
};
</script>
<style>
 @font-face {
    font-family: opensanslight;
    src: url("./assets/fonts/opensanslight.ttf");
  }

@font-face {
  font-family: opensansregular;
  src: url("./assets/fonts/opensansregular.ttf");
}
@font-face {
  font-family: opensanssemibold;
  src: url("./assets/fonts/opensanssemibold.ttf");
}
@font-face {
  font-family: opensansbold;
  src: url("./assets/fonts/opensansbold.ttf");
}
@font-face {
  font-family: opensansextrabold;
  src: url("./assets/fonts/opensansextrabold.ttf");
}
.opensansbold
{
font-family:opensansbold

}
</style>
