import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [

        {
            name: 'Home',
            path: '/',
            component: () =>
                import ('./components/Home'),
        },
        // {
        //     name: 'navbar',
        //     path: '/navbar',
        //     component: () =>
        //         import ('./components/navbar'),
        // },
        {
            name: "about",
            path: '/about',
            component: () =>
                import ('./components/about')
        },
        {
            name: "privacyPolicy",
            path: '/privacyPolicy',
            component: () =>
                import ('./components/privacyPolicy')
        }
    ]

})


export default router