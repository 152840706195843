import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import InfiniteSlideBar from 'vue-infinite-slide-bar'
import VueAnalytics from 'vue-analytics'
Vue.component('InfiniteSlideBar', InfiniteSlideBar)
Vue.config.productionTip = false
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)
Vue.use(VueAnalytics, {
    id: 'UA-188313676-1',router
  })
// window.axios = require('axios')

// Vue.prototype.baseURL = 'http://15.206.67.113' // URL for File retriving Globaly initialized
// axios.defaults.baseURL = 'http://15.206.67.113' //Production Server URL

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')